<template>
	<div>
		<b-modal
			id="modal-add-bonus"
			cancel-variant="outline-secondary"
			ok-title="Add"
			cancel-title="Close"
			ok-variant="primary"
			centered
			title="New Bonus"
			size="lg"
			no-enforce-focus
			@ok="confirmAddBonus"
			@hidden="resetForm"
		>
			<!-- form -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<b-form
					@submit.prevent="handleSubmit(validationFormBonus)"
					@reset.prevent="resetForm"
				>

					<!-- Copy Bonus -->
					<b-row>
						<b-col
							cols="12"
							class="mb-2 border-bottom"
						>
							<b-form-group
								label-for="copyBonus"
								class="d-inline-block w-50 mr-2 mb-0 pt-2 pb-3"
							>
								<label class="">Copy Bonus</label>
								<v-select
									id="copyBonus"
									:options="bonusCodeOptions"
									:reduce="val => val"
									label="name"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									placeholder="Select Bonus"
									@input="selectBonusCode"
								/>
							</b-form-group>
							<b-button
								variant="primary"
								@click="copyBonusCode"
							>
								Copy
							</b-button>
						</b-col>
					</b-row>

					<b-row>
						<!-- Name -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<!-- Field: Name -->
							<b-form-group
								label-for="name"
							>
								<label class="mb-1"><span class="text-danger">*</span> Name</label>
								<validation-provider
									#default="{ errors }"
									name="bonusName"
									rules="required"
								>
									<b-form-input
										id="name"
										v-model="bonusData.name"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										placeholder="Enter Bonus Name"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Group -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="group"
							>
								<label class="mb-1"><span class="text-danger">*</span> Group</label>
								<validation-provider
									#default="{ errors }"
									name="group"
									rules="required"
								>
									<v-select
										v-model="bonusData.bonus_group_id"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="groupNameOptions"
										:reduce="val => val.id"
										:clearable="false"
										placeholder="Select Bonus Group"
										label="name"
										input-id="group"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Code -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="code"
							>
								<label class="mb-1">Code</label>
								<b-form-input
									id="code"
									v-model="bonusData.code"
									placeholder="Enter bonus code"
								/>
							</b-form-group>
						</b-col>

						<!-- Value -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="value"
							>
								<label class="mb-1">Value</label>
								<validation-provider
									#default="{ errors }"
									name="value"
									rules="numeric|min_value:1"
								>
									<b-form-input
										id="value"
										v-model="bonusData.value"
										placeholder="Enter bonus value, Must be at least 1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Type -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="type"
							>
								<label class="mb-1"><span class="text-danger">*</span> Type</label>
								<validation-provider
									#default="{ errors }"
									name="type"
									rules="required"
								>
									<v-select
										v-model="bonusData.type"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="typeOptions"
										:reduce="val => val.value"
										:clearable="false"
										placeholder="Select Type"
										input-id="type"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Rollover -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="rollover"
							>
								<label class="mb-1">Rollover</label>
								<validation-provider
									#default="{ errors }"
									name="rollover"
									rules="numeric|numeric|min_value:1|max_value:50"
								>
									<b-form-input
										id="rollover"
										v-model="bonusData.rollover"
										placeholder="Enter bonus rollover, Must be from 1 - 50."
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Currency -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="currency"
							>
								<label class="mb-1">Currency</label>
								<validation-provider
									#default="{ errors }"
									name="currency"
									rules=""
								>
									<v-select
										v-model="bonusData.currency"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="currencyOptions"
										:reduce="val => val.value"
										:clearable="true"
										placeholder="Select Currency"
										label="value"
										input-id="currency"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Max Bonus Amount -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="maxAmount"
							>
								<label class="mb-1">Max Bonus Amount</label>
								<validation-provider
									#default="{ errors }"
									name="maxAmount"
									rules="numeric"
								>
									<b-form-input
										id="maxAmount"
										v-model="bonusData.max_bonus_amount"
										placeholder="Enter max bonus amount"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Min Transfer Amount -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="minTransferAmount"
							>
								<label class="mb-1">Min Transfer Amount</label>
								<validation-provider
									#default="{ errors }"
									name="minTransferAmount"
									rules="numeric"
								>
									<b-form-input
										id="minTransferAmount"
										v-model="bonusData.min_transfer"
										placeholder="Enter Min Transfer Amount"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="is_verify"
							>
								<label class="mb-1">Có cần xác thực phone và email</label>
								<v-select
									v-model="bonusData.is_verify"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="yesNoOptions"
									:reduce="val => val.value"
									label="text"
									input-id="is_verify"
									placeholder="Select"
								/>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="is_new"
							>
								<label class="mb-1">Có new hay không?</label>
								<v-select
									v-model="bonusData.is_new"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="yesNoOptions"
									:reduce="val => val.value"
									label="text"
									input-id="is_new"
									placeholder="Select"
								/>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="disposable"
							>
								<label class="mb-1">Dùng 1 lần hay nhiều lần?</label>
								<validation-provider
									#default="{ errors }"
									name="disposable"
								>
									<v-select
										v-model="bonusData.disposable"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="disposableOptions"
										:reduce="val => val.value"
										placeholder="Select "
										label="text"
										input-id="disposable"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="limit_daily"
							>
								<label class="mb-1">Limit daily</label>
								<b-form-input
									id="limit_daily"
									v-model="bonusData.limit_daily"
									placeholder="Enter Limit Daily, Must be at least 1"
								/>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="status"
							>
								<label class="mb-1">Trạng thái</label>
								<validation-provider
									#default="{ errors }"
									name="status"
								>
									<v-select
										v-model="bonusData.status"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="statusOptions"
										:reduce="val => val.value"
										placeholder="Select "
										label="text"
										input-id="status"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Product -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="product"
							>
								<label class="mb-1">Product</label>
								<v-select
									v-model="bonusData.product_ids"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="productOptions"
									:reduce="val => val.id"
									placeholder="Select Product"
									label="name"
									input-id="product"
									multiple
								/>
							</b-form-group>
						</b-col>

						<!-- Level -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="level"
							>
								<label class="mb-1">Level</label>
								<v-select
									v-model="bonusData.level_ids"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="levelOptions"
									:reduce="val => val.id"
									placeholder="Select Label"
									label="name"
									input-id="level"
									multiple
								/>
							</b-form-group>
						</b-col>

						<!-- Level -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="days"
							>
								<label class="mb-1">Days</label>
								<v-select
									v-model="bonusData.days"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="dayOptions"
									:reduce="val => val.value"
									placeholder="Select Days"
									label="text"
									input-id="days"
									multiple
								/>
							</b-form-group>
						</b-col>

						<!-- Bonus Type -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="type"
							>
								<label class="mb-1"><span class="text-danger">*</span> Bonus Type</label>
								<validation-provider
									#default="{ errors }"
									name="bonusType"
									rules="required"
								>
									<v-select
										v-model="bonusData.bonus_type"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="bonusTypeOptions"
										:reduce="val => val.id"
										label="name"
										:clearable="false"
										placeholder="Select Bonus Type"
										input-id="type"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Start Effective -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="startEffective"
							>
								<label class="mb-1">Start Effective</label>
								<flat-pickr
									id="startEffective"
									v-model="bonusData.start_effective"
									class="form-control"
									placeholder="YYYY-MM-DD 00:00:00"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
								/>
							</b-form-group>
						</b-col>

						<!-- End Effective -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="startEffective"
							>
								<label class="mb-1">End Effective</label>
								<flat-pickr
									id="startEffective"
									v-model="bonusData.end_effective"
									class="form-control"
									placeholder="YYYY-MM-DD 00:00:00"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
								/>
							</b-form-group>
						</b-col>

						<!-- Start SignUp -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="startSignUp"
							>
								<label class="mb-1">Start Signup</label>
								<flat-pickr
									id="startSignup"
									v-model="bonusData.start_signup"
									class="form-control"
									placeholder="YYYY-MM-DD 00:00:00"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
								/>
							</b-form-group>
						</b-col>

						<!-- End SignUp -->
						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="startSignUp"
							>
								<label class="mb-1">End Signup</label>
								<flat-pickr
									id="startSignup"
									v-model="bonusData.end_signup"
									class="form-control"
									placeholder="YYYY-MM-DD 00:00:00"
									:config="{
										enableTime: true,
										allowInput: true,
										dateFormat: 'Y-m-d H:i:ss',
										time_24hr: true,
									}"
								/>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
						>
							<b-form-group
								label-for="is_first_deposit"
							>
								<label class="mb-1">Áp dụng cho lần nạp đầu tiên không?</label>
								<v-select
									v-model="bonusData.is_first_deposit"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="yesNoOptions"
									:reduce="val => val.value"
									label="text"
									input-id="is_first_deposit"
									placeholder="Select"
								/>
							</b-form-group>
						</b-col>

						<b-col
							cols="12"
							md="6"
							xl="4"
							v-if="bonusData.is_first_deposit == 2"
						>
							<b-form-group
								label-for="deposit_times"
							>
								<label class="mb-1">Lần nạp áp dụng</label>
								<b-form-input
									id="deposit_times"
									v-model="bonusData.deposit_times"
									placeholder="Nhập giá trị lớn hơn 1"
								/>
							</b-form-group>
						</b-col>
						
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="content"
							>
								<label class="mb-1"><span class="text-danger">*</span> {{ $t('Content') }}</label>
								<validation-provider
									#default="{ errors }"
									name="content"
									rules="required"
								>
									<Editor
                                        :api-key = API_KEY
                      					:init="init"
										v-model="bonusData.content"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										class="h-100"
                  					/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="banner"
							>
								<label for=""><span class="text-danger">*</span> {{ $t('Banner') }} <span class="text-secondary">(Không > 10Mb)</span></label>
								<validation-provider
									#default="{ errors }"
									name="banner"
									rules="size:10240"
								>
									<b-form-file
										v-model="bonusData.banner"
										input-id="banner"
										accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
							<b-button
								v-show="bonusData.banner"
								variant="outline-danger"
								class="btn-sm btn-icon"
								@click="bonusData.banner = null"
							>
								<feather-icon icon="XIcon"/>
							</b-button>
						</b-col>						
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BFormInvalidFeedback,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import baseUrl from '@/libs/base-url'
import Editor from '@tinymce/tinymce-vue'
import API_KEY from "@/auth/apikey/apikeyeditor";
export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		BFormInvalidFeedback,
		ValidationProvider,
		ValidationObserver,
		vSelect,
		flatPickr,
    	Editor
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		bonusCodeOptions: {
			type: Array,
			default: null,
		},
		groupNameOptions: {
			type: Array,
			default: null,
		},
		currencyOptions: {
			type: Array,
			default: null,
		},
		productOptions: {
			type: Array,
			default: null,
		},
		levelOptions: {
			type: Array,
			default: null,
		},
		typeOptions: {
			type: Array,
			default: null,
		},
		bonusTypeOptions: {
			type: Array,
			default: null,
		},
		content: {
			type: Object,
			required: false,
			default: null,
		},
		bonusGroupId: {
			type: Number,
			required: false,
			default: null,
		},
	},
	data() {
		return {
            API_KEY,
			required,
			copyBonusCodeData: null,
            init:{
                height: 600,
                content_style: this.$store.state.appConfig.layout.skin === "dark" ? "body { color: white; background-color:#293046 }" : "",
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'wordcount'
                ],
                toolbar: ' undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent inden |image',
                skin: this.$store.state.appConfig.layout.skin === "dark" ? 'oxide-dark' : '',
                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: function (cb, value, meta) {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                    input.onchange = function () {
                        var file = this.files[0];

                        var reader = new FileReader();
                        reader.onload = function () {
                            var id = 'blobid' + (new Date()).getTime();
                            var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                            var base64 = reader.result.split(',')[1];
                            var blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);
                            cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                    };

                    input.click();

                },

            }

    }
	},
	watch: {
		bonusData: {
			handler(newVal) {
				this.bonusData.start_effective = newVal.start_effective
				this.bonusData.end_effective = newVal.end_effective

				this.bonusData.start_signup = newVal.start_signup
				this.bonusData.end_signup = newVal.end_signup
				

				if (newVal.product_id == null) {
					this.bonusData.product_id = ''
				}

				if (newVal.currency == null) {
					this.bonusData.currency = ''
				}
			},
			deep: true,
		},
	},
	methods: {
		validationFormBonus() {
			this.$refs.refFormObserver.validate().then(success => {
				if (success) {
					store.dispatch('bonus/addBonusCode', this.bonusData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code == '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										this.$bvModal.hide('modal-add-bonus')
										this.resetForm()
									})
									this.$emit('refetch-data')
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		confirmAddBonus(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormBonus()
		},
		selectBonusCode(bonusCode) {
			this.copyBonusCodeData = bonusCode
		},
		copyBonusCode() {
			if (this.copyBonusCodeData) {
				this.bonusData = {
					name: '',
					bonus_group_id: this.copyBonusCodeData.group_id,
					code: '',
					value: this.copyBonusCodeData.value,
					rollover: this.copyBonusCodeData.rollover,
					currency: this.copyBonusCodeData.currency,
					max_bonus_amount: this.copyBonusCodeData.max_bonus_amount / 1000,
					min_transfer: this.copyBonusCodeData.min_transfer_amount / 1000,
					product_id: this.copyBonusCodeData.product_id || '',
					start_effective: this.copyBonusCodeData.start_effective || '',
					end_effective: this.copyBonusCodeData.end_effective || '',
					start_signup: this.copyBonusCodeData.start_signup || '',
					end_signup: this.copyBonusCodeData.end_signup || '',
					type: this.copyBonusCodeData.value_type,
					content: this.copyBonusCodeData.content,
					bonus_type: this.copyBonusCodeData.bonus_type_id,
					is_verify: this.copyBonusCodeData.is_verify,
					is_new: this.copyBonusCodeData.is_new,
					disposable: this.copyBonusCodeData.disposable,
					product_ids: this.copyBonusCodeData.product_ids && this.copyBonusCodeData.product_ids != 'null' ? this.copyBonusCodeData.product_ids.split(',').map(Number) : '',
					level_ids: this.copyBonusCodeData.level_ids && this.copyBonusCodeData.level_ids != 'null' ? this.copyBonusCodeData.level_ids.split(',').map(Number) : '',
					days: this.copyBonusCodeData.days && this.copyBonusCodeData.days != 'null' ? this.copyBonusCodeData.days.split(',').map(Number) : '',
					status: this.copyBonusCodeData.status,
					limit_daily: this.copyBonusCodeData.limit_daily,
					deposit_times: this.copyBonusCodeData.deposit_times,
					is_first_deposit: this.copyBonusCodeData.is_first_deposit
				}
			}
		},
		resetForm() {
			this.bonusData = {
				name: '',
				bonus_group_id: '',
				code: '',
				value: '',
				rollover: '',
				currency: 'VND',
				max_bonus_amount: '',
				min_transfer: '',
				product_id: '',
				start_effective: '',
				end_effective: '',
				start_signup: '',
				end_signup: '',
				type: '',
				content: '',
				banner: null,
				bonus_type: '',
				is_verify: '',
				is_new: '',
				disposable: '',
				product_ids: '',
				level_ids: '',
				days: '',
				status: '',
				limit_daily: '',
				deposit_times: '',
				is_first_deposit: ''
			}
		}
	},
	setup() {
		const toast = useToast()
		const blankBonusData = {
			name: '',
			bonus_group_id: '',
			code: '',
			value: '',
			rollover: '',
			currency: 'VND',
			max_bonus_amount: '',
			min_transfer: '',
			product_id: '',
			start_effective: '',
			end_effective: '',
			start_signup: '',
			end_signup: '',
			type: '',
			start_effective: '',
			end_effective: '',
			start_signup: '',
			end_signup: '',
			content: '',
			banner: null,
			bonus_type: '',
			deposit_times: '',
			is_first_deposit: ''
		}
		const bonusData = ref(JSON.parse(JSON.stringify(blankBonusData)));
		const yesNoOptions = [
			{ text: "Có", value: 1 },
			{ text: "Không", value: 2 },
		]
		const disposableOptions = [
			{ text: "1 lần", value: 1 },
			{ text: "Nhiều lần", value: 2 },
		]
		const statusOptions = [
			{ text: "Active", value: 1 },
			{ text: "Inactive", value: 2 },
		]
		const dayOptions = [
			{ text: "Thứ 2", value: 2 },
			{ text: "Thứ 3", value: 3 },
			{ text: "Thứ 4", value: 4 },
			{ text: "Thứ 5", value: 5 },
			{ text: "Thứ 6", value: 6 },
			{ text: "Thứ 7", value: 7 },
			{ text: "Chủ nhật", value: 8 },
		]

		return {
			bonusData,
			yesNoOptions,
			disposableOptions,
			statusOptions,
			dayOptions
		}
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
