export default [
  /* MEMBER */
  {
    path: "/bonus/list",
    name: "bonus-list",
    component: () => import("@/views/bonus/BonusList.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bonus",
        },
        {
          text: "Bonus List",
          active: true,
        },
      ],
      action: "view",
      resource: "bonuscode",
    },
  },
  {
    path: "/bonus/group",
    name: "bonus-group",
    component: () => import("@/views/bonus/BonusGroup.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bonus",
        },
        {
          text: "Bonus Group",
          active: true,
        },
      ],
      action: "view",
      resource: "bonusgroup",
    },
  },
  {
    path: "/bonus/member/bonus",
    name: "bonus-member-bonus",
    component: () => import("@/views/bonus/MemberBonus.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bonus",
        },
        {
          text: "Members Bonus",
          active: true,
        },
      ],
      action: "view",
      resource: "userbonus",
    },
  },
  {
    path: "/bonus/member/agent-lock",
    name: "bonus-member-agent-lock",
    component: () => import("@/views/bonus/MemberAgentLock.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bonus",
        },
        {
          text: "Agent Lock",
          active: true,
        },
      ],
      action: "update",
      resource: "role",
    },
  },
  {
    path: "/bonus/apply-bonus",
    name: "bonus-apply-bonus",
    component: () => import("@/views/bonus/ApplyBonus.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bonus",
        },
        {
          text: "Apply Bonus",
          active: true,
        },
      ],
      action: "update",
      resource: "role",
    },
  },

  {
    path: "/bonus/manager",
    name: "bonus-manager",
    component: () => import("@/views/bonus/BonusManager.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bonus",
        },
        {
          text: "Bonus Transfer",
          active: true,
        },
      ],
      action: "view",
      resource: "userbonus",
    },
  },

  {
    path: "/bonus/user-bonus-request",
    name: "user-bonus-request",
    component: () => import("@/views/bonus/UserBonusRequest.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bonus",
        },
        {
          text: "User Bonus Request",
          active: true,
        },
      ],
      action: "read",
      resource: "bonusrequestmanagement",
    },
  },

  {
    path: "/bonus/new-member",
    name: "bonus-verify-daily",
    component: () => import("@/views/bonus/BonusNewMember.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bonus",
        },
        {
          text: "Bonus Verify New Member",
          active: true,
        },
      ],
      action: "view",
      resource: "bonusnewmember",
    },
  },
  {
    path: "/manager/cashback",
    name: "manager-cashback",
    component: () => import("@/views/bonus/ManagerCashback.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bonus",
        },
        {
          text: "Bonus Deposit Cashback",
          active: true,
        },
      ],
      action: "view",
      resource: "bonusdepositcashback",
    },
  },
  {
    path: "/bonus/user-roll-call",
    name: "user-roll-call",
    component: () => import("@/views/bonus/UserRollCall.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bonus",
        },
        {
          text: "User Roll Call",
          active: true,
        },
      ],
      action: "read",
      resource: "userrollcall",
    },
  },
];
