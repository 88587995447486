/* eslint-disable eol-last */
/* eslint-disable comma-dangle */
/* eslint-disable no-tabs */
/* eslint-disable indent */
export default [
  {
    title: "Bonus Management",
    icon: "GiftIcon",
    children: [
      {
        title: "Bonus",
        route: "bonus-list",
        action: "view",
        resource: "bonuscode",
      },
      {
        title: "Bonus Group",
        route: "bonus-group",
        action: "view",
        resource: "bonusgroup",
      },
      {
        title: "Bonus Transfer",
        route: "bonus-manager",
        action: "view",
        resource: "userbonus",
      },
      {
        title: "User Bonus Request",
        route: "user-bonus-request",
        action: "read",
        resource: "bonusrequestmanagement",
      },
      {
        title: "Bonus Verify New Member",
        route: "bonus-verify-daily",
        action: "view",
        resource: "bonusnewmember",
      },
      {
        title: "Bonus Deposit Cashback",
        route: "manager-cashback",
        action: "view",
        resource: "bonusdepositcashback",
      },
      {
        title: "User Roll Call",
        route: "user-roll-call",
        action: "read",
        resource: "userrollcall",
      },
      // {
      //     title: 'Member Bonus',
      //     route: 'bonus-member-bonus',
      //     action: 'view',
      //     resource: 'user',
      // },
      // {
      //     title: 'Member Agent Lock',
      //     route: 'bonus-member-agent-lock',
      //     action: 'view',
      //     resource: 'user',
      // },
      // {
      //     title: 'Apply Bonus',
      //     route: 'bonus-apply-bonus',
      //     action: 'view',
      //     resource: 'user',
      // }
    ],
  },
];
