<template>
    <div>
      <b-card no-body class="mb-0">
        <b-table
          ref="refAffiliateCodeListTable"
          class="position-relative table-white-space"
          :items="affiliateCodeList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(index)="data">
            {{ perPage * (currentPage - 1) + data.index + 1 }}
          </template>

          <template #cell(link)="data">
            <div> <span :id="data.item.link">{{ data.item.link }} </span>
                <span style="margin-left: 10px;cursor: pointer;" class="text-nowrap" @click="copyToClipboard(data.item.link)">
                    <feather-icon icon="CopyIcon"/>
                </span>
            </div>
          </template>
  
          <template #cell(created_at)="data">
            {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
          </template>
        </b-table>
  
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNewUserDeposit"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormGroup,
    VBTooltip,
  } from "bootstrap-vue";
  import store from "@/store";
  import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
  import { numberFormat, formatDateTime } from "@core/utils/filter";
  import useAgencyRadioList from "@/views/marketing/useAgencyRadioList";
  import marketingStoreModule from "@/views/marketing/marketingStoreModule";
  import baseUrl from "@/libs/base-url";
  import i18n from "@/libs/i18n";
  import flatPickr from "vue-flatpickr-component";
  import { useToast } from "vue-toastification/composition";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  
  export default {
    components: {
      // AddAgencyRadioModal: () => import("./AddAgencyRadioModal.vue"),
      // UpdateAgencyRadioModal: () => import('./UpdateAgencyRadioModal.vue'),
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BPagination,
      BFormGroup,
      flatPickr
    },
    directives: {
      "b-tooltip": VBTooltip,
    },
    data() {
      return {
        agencyRadioDetail: null,
      };
    },
    methods: {},
    setup() {
      const toast = useToast();
      const MARKETING_MODULE_NAME = "marketing";
      if (!store.hasModule(MARKETING_MODULE_NAME))
        store.registerModule(MARKETING_MODULE_NAME, marketingStoreModule);
      onUnmounted(() => {
        if (store.hasModule(MARKETING_MODULE_NAME))
          store.unregisterModule(MARKETING_MODULE_NAME);
      });

      const refAffiliateCodeListTable = ref(null);
      const affiliateCodeList = ref([]);

      const tableColumns = [
        { key: "index", label: "#" },
        { key: "code", label: "Code" },
        { key: "link", label: "Link" },
        { key: "click", label: "Lượt click" },
        { key: "user_count", label: "Số lượng user" },
        { key: "created_at", label: "Created At" },
      ];
      const perPage = ref(25);
      const totalNewUserDeposit = ref(0);
      const currentPage = ref(1);
    

      const refetchData = () => {
        refAffiliateCodeListTable.value.refresh();
      };

      watch([currentPage], () => {
        refetchData();
        getAffiliateCodeList();
      });

      const getAffiliateCodeList = (ctx, callback) => {
        store
          .dispatch("marketing/fetchAffiliateCode", {
            pageNumber: currentPage.value,
          })
          .then((response) => {
            if (response.data.code == "00") {
              affiliateCodeList.value = response.data.data;
              totalNewUserDeposit.value = response.data.count;
              perPage.value =
                currentPage.value == 1 ? response.data.data.length : 25;
            }
          });
      };

      getAffiliateCodeList();

      const dataMeta = computed(() => {
        const localItemsCount = refAffiliateCodeListTable.value
          ? refAffiliateCodeListTable.value.localItems.length
          : 0;
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalNewUserDeposit.value,
        };
      });

      const copyToClipboard = (id) => {
        const r = document.createRange();
        r.selectNode(document.getElementById(id));
        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(r);
        document.execCommand('copy');
        window.getSelection()?.removeAllRanges();
        // openMessage('success', 'Đã sao chép');
        toast({
            component: ToastificationContent,
            props: {
            title: 'Đã sao chép',
            icon: "AlertTriangleIcon",
            variant: "success",
            },
        });
      };
  
      return {
        tableColumns,
        perPage,
        currentPage,
        totalNewUserDeposit,
        dataMeta,
        refAffiliateCodeListTable,
        refetchData,
        numberFormat,
        formatDateTime,
        baseUrl,
        affiliateCodeList,
        copyToClipboard
      };
    },
  };
  </script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>