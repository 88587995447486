/* eslint-disable eol-last */
/* eslint-disable comma-dangle */
/* eslint-disable no-tabs */
/* eslint-disable indent */
export default [
    {
        title: 'Marketing Management',
        icon: 'TargetIcon',
        children: [
            // {
            //     title: 'Daily rebate',
            //     route: 'marketing-daily-rebate',
            //     action: 'read',
            //     resource: 'Auth',
            // },
            // {
            //     title: 'Daily vip',
            //     route: 'marketing-daily-vip',
            //     action: 'read',
            //     resource: 'Auth',
            // },
            // {
            //     title: 'Daily reward',
            //     route: 'marketing-daily-reward',
            //     action: 'read',
            //     resource: 'Auth',
            // },
            // {
            //     title: 'Wager summary',
            //     route: 'marketing-wager-summary',
            //     action: 'read',
            //     resource: 'Auth',
            // },
            {
                title: 'Rebate Ratio',
                route: 'marketing-rebate-ratio',
                action: 'read',
                resource: 'rebateratio',
            },
            {
                title: 'Upgrade Level',
                route: 'marketing-upgrade-level',
                action: 'read',
                resource: 'levelrankmanagement',
            },
            {
                title: 'New deposit report',
                route: 'marketing-new-deposit-report',
                action: 'read',
                resource: 'usernewdepositreportmanagement',
            },
            {
                title: 'Affiliate Code',
                route: 'marketing-affiliate-code',
                action: 'view',
                resource: 'affiliatecode',
            },
            /* {
                title: 'Reward setting',
                route: 'marketing-reward-setting',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Level setting',
                route: 'marketing-level-setting',
                action: 'read',
                resource: 'Auth',
            },
            {
                title: 'Rebate ratio',
                route: 'marketing-rebate-ratio',
                action: 'read',
                resource: 'Auth',
            }, */
        ]
    }
]