<template>
  <div>
    <b-modal
      id="modal-edit-bonus-new-member"
      cancel-variant="outline-secondary"
      ok-title="Ok"
      cancel-title="Close"
      ok-variant="primary"
      centered
      size="lg"
      title="Edit Bonus New Member"
      no-close-on-backdrop
      @ok="confirmEditBonusNewMember"
      @hidden="resetModal()"
    >
      <b-overlay :show="show" variant="transparent" no-wrap />
      <!-- form -->
      <validation-observer ref="editBonusNewMember">
        <b-form
          method="POST"
          class="auth-reset-password-form mt-2"
          @submit.prevent="validationFormBonusNewMember"
        >
          <!-- Field: Name -->
          <!-- <b-form-group label-for="name">
            <label class="mb-1"><span class="text-danger">*</span> Name</label>
            <validation-provider
              #default="{ errors }"
              name="limit"
              rules="required"
              hidden
            >
              <b-form-input
                id="name"
                v-model="bonusNewMemberData.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->

          <!-- Field: User Name -->
          <!-- <b-form-group label-for="username">
            <label class="mb-1"
              ><span class="text-danger">*</span> User Name</label
            >
            <validation-provider
              #default="{ errors }"
              name="limit"
              rules="required"
              disabled
            >
              <b-form-input
                id="username"
                v-model="bonusNewMemberData.username"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter username"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->

          <!-- Product -->
          <b-col
              cols="12"
              md="6"
              xl="4"
              v-if="bonusNewMemberData.status == 2"
          >

            <b-form-group
                label-for="product"
            >
              <label class="mb-1"><span class="text-danger">*</span> Product</label>
              <validation-provider
                  #default="{ errors }"
                  name="Product"
                  rules="required"
              >
                <v-select
                    v-model="bonusNewMemberData.product_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productOptions"
                    :reduce="val => val.id"
                    placeholder="Select Product"
                    label="name"
                    input-id="product"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Status -->
          <b-col cols="12">
            <b-form-group label-for="status">
              <label class="mb-1 d-block"><span class="text-danger">*</span> Status</label>
              <validation-provider
                  #default="{ errors }"
                  name="Status"
                  rules="required"
              >
                <!-- <b-form-radio
                  v-model="bonusNewMemberData.status"
                  name="status"
                  value="1"
                  inline
                >
                  Pending
                </b-form-radio> -->
                <b-form-radio
                  v-model="bonusNewMemberData.status"
                  name="status"
                  value="2"
                  inline
                >
                  Approve
                </b-form-radio>
                <b-form-radio
                  v-model="bonusNewMemberData.status"
                  name="status"
                  value="3"
                  inline
                >
                  Reject
                </b-form-radio>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BOverlay,
  BImg,
  BFormSpinbutton,
  BFormRadio,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
  BFormFile,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import bonusStoreModule from "@/views/bonus/bonusStoreModule";
/* // eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor' */
// import paymentTypeStoreModule from './paymentTypeStoreModule'

export default {
  components: {
    BOverlay,
    BImg,
    BFormSpinbutton,
    BFormRadio,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BFormFile,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    // quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    bonusNewMemberDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bonusNewMemberData: {
        id: "",
        username: "",
        name: "",
        status: "",
        product_id: ""
      },
      show: false,
    };
  },
  watch: {
    bonusNewMemberDetail: {
      handler(newVal) {
        this.fillBonusNewMemberData(newVal);
      }
    }
  },
  mounted() {
    this.getBonusCodeProduct();
  },
  methods: {
    validationFormBonusNewMember() {
      this.$refs.editBonusNewMember.validate().then((success) => {
        if (success) {
          store
            .dispatch("bonus/editBonusNewMember", this.bonusNewMemberData)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === "00") {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || "success",
                      icon: "CheckIcon",
                      variant: "success",
                    },
                  });
                  this.$nextTick(() => {
                    setTimeout(() => {
                      this.$bvModal.hide("modal-edit-bonus-new-member");
                      this.resetModal();
                    }, "500");
                  });
                  this.$emit("refetch-data");
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                }
              }
            })
            .catch((error) => {
              const { response } = error;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Must be one of Approve or Reject" || response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    fillBonusNewMemberData(data) {
      this.bonusNewMemberData.id = data.id;
      this.bonusNewMemberData.username = data.username;
      this.bonusNewMemberData.name = data.name;
      //   this.bonusNewMemberData.user_id = data.user_id;
      //   this.bonusNewMemberData.email = data.email;
      //   this.bonusNewMemberData.name = data.name;

      /* this.bonusNewMemberData.content = data.content
			this.bonusNewMemberData.banner = ''
			this.bonusNewMemberData.showBanner = data.banner ? baseUrl + data.banner : null, */
      this.bonusNewMemberData.status = "";
      this.bonusNewMemberData.product_id = "";
    },
    resetModal() {
      this.$emit("update:bonusNewMemberDetail", null);
      this.bonusNewMemberData.id = "";
      this.bonusNewMemberData.name = "";
      this.bonusNewMemberData.username = "";
      /* this.bonusNewMemberData.content = ''
			this.bonusNewMemberData.banner = '' */
      this.bonusNewMemberData.status = "";
    },
    confirmEditBonusNewMember(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationFormBonusNewMember();
    },
    getBonusCodeProduct() {
      store.dispatch("bonus/fetchBonusCodeProduct").then((response) => {
        if (response.data.code == "00") {
          this.productOptions = response.data.data;
        }
      });
    }
  },
  setup() {
    // if (!store.hasModule('payment-type')) store.registerModule('payment-type', paymentTypeStoreModule)
    if (!store.hasModule("bonus"))
      store.registerModule("bonus", bonusStoreModule);
    const productOptions = ref([]);
    return {productOptions};
  },
};
</script>
<style lang="scss">
// @import '@core/scss/vue/libs/quill.scss';
</style>
