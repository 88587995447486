/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class BonusService {
  getBonusCodeLists(queryParams) {
    const pars = {
      code: queryParams.code,
      group_id: queryParams.group_id,
      currency: queryParams.currency,
      product_id: queryParams.product_id,
      discount_type: queryParams.discount_type,
      pageNumber: queryParams.page,
      status: queryParams.status,
      start_effective: queryParams.start_effective,
      end_effective: queryParams.end_effective,
    };
    return instance.get("/api/bonus-code/index", {
      headers: authHeader(),
      params: pars,
    });
  }
  getManagerCashbackLists(queryParams) {
    const pars = {
      deposit_ref: queryParams.deposit_ref,
      ref: queryParams.ref,
      username: queryParams.username,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      pageNumber: queryParams.page,
      date: queryParams.date
    };
    return instance.get("/api/deposit-bonus-cashback/index", {
      headers: authHeader(),
      params: pars,
    });
  }
  

  getBonusCodeProduct() {
    return instance.get("/api/bonus-code/product/index", {
      headers: authHeader(),
    });
  }

  getBonusCodeLevel() {
    return instance.get("/api/Level/index", {
      headers: authHeader(),
    });
  }

  addBonusCode(bonusData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("name", bonusData.name);
    data.append("bonus_group_id", bonusData.bonus_group_id);
    data.append("code", bonusData.code);
    data.append("value", bonusData.value ? bonusData.value : "");
    data.append("rollover", bonusData.rollover);
    data.append("currency", bonusData.currency);
    data.append(
      "max_bonus_amount",
      bonusData.max_bonus_amount ? bonusData.max_bonus_amount * 1000 : ""
    );
    data.append(
      "min_transfer",
      bonusData.min_transfer ? bonusData.min_transfer * 1000 : ""
    );
    data.append("product_ids", bonusData.product_ids && bonusData.product_ids != 'undefined' ? bonusData.product_ids : '');
    data.append("start_effective", bonusData.start_effective);
    data.append("end_effective", bonusData.end_effective);
    data.append("start_signup", bonusData.start_signup);
    data.append("end_signup", bonusData.end_signup);
    data.append("type", bonusData.type);
    data.append("content", bonusData.content);
    data.append("banner", bonusData.banner);
    data.append("bonus_type", bonusData.bonus_type && bonusData.bonus_type != 'undefined' ? bonusData.bonus_type : '');
    data.append("is_verify", bonusData.is_verify && bonusData.is_verify != 'undefined' ? bonusData.is_verify : '');
    data.append("is_new", bonusData.is_new && bonusData.is_new != 'undefined' ? bonusData.is_new : '');
    data.append("disposable", bonusData.disposable && bonusData.disposable != 'undefined' ? bonusData.disposable : '');
    data.append("days", bonusData.days && bonusData.days != 'undefined' ? bonusData.days : '');
    data.append("level_ids", bonusData.level_ids && bonusData.level_ids != 'undefined' ? bonusData.level_ids : '');
    data.append("limit_daily", bonusData.limit_daily && bonusData.limit_daily != 'undefined' ? bonusData.limit_daily : '');
    data.append("status", bonusData.status && bonusData.status != 'undefined' ? bonusData.status : '');
    data.append("is_first_deposit", bonusData.is_first_deposit && bonusData.is_first_deposit != 'undefined' ? bonusData.is_first_deposit : '');
    data.append("deposit_times", bonusData.deposit_times && bonusData.deposit_times != 'undefined' ? bonusData.deposit_times : '');

    return instance.post("/api/bonus-code/create", data, {
      headers: authHeader(),
    });
  }

  editBonusCode(bonusData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("bonus_code_id", bonusData.bonus_code_id);
    data.append("name", bonusData.name);
    data.append("bonus_group_id", bonusData.bonus_group_id);
    data.append("value", bonusData.value ? bonusData.value : "");
    data.append("rollover", bonusData.rollover);
    data.append("currency", bonusData.currency);
    data.append(
      "max_bonus_amount",
      bonusData.max_bonus_amount ? bonusData.max_bonus_amount * 1000 : ""
    );
    data.append(
      "min_transfer",
      bonusData.min_transfer ? bonusData.min_transfer * 1000 : ""
    );
    data.append("product_ids", bonusData.product_ids && bonusData.product_ids != 'undefined' ? bonusData.product_ids : '');
    data.append("start_effective", bonusData.start_effective);
    data.append("end_effective", bonusData.end_effective);
    data.append("start_signup", bonusData.start_signup);
    data.append("end_signup", bonusData.end_signup);
    data.append("type", bonusData.type);
    data.append("status", bonusData.status);
    data.append("content", bonusData.content);
    data.append("banner", bonusData.banner);
    data.append("bonus_type", bonusData.bonus_type && bonusData.bonus_type != 'undefined' ? bonusData.bonus_type : '');
    data.append("is_verify", bonusData.is_verify && bonusData.is_verify != 'undefined' ? bonusData.is_verify : '');
    data.append("is_new", bonusData.is_new && bonusData.is_new != 'undefined' ? bonusData.is_new : '');
    data.append("disposable", bonusData.disposable && bonusData.disposable != 'undefined' ? bonusData.disposable : '');
    data.append("days", bonusData.days && bonusData.days != 'undefined' ? bonusData.days : '');
    data.append("level_ids", bonusData.level_ids && bonusData.level_ids != 'undefined' ? bonusData.level_ids : '');
    data.append("limit_daily", bonusData.limit_daily && bonusData.limit_daily != 'undefined' ? bonusData.limit_daily : '');
    data.append("is_first_deposit", bonusData.is_first_deposit && bonusData.is_first_deposit != 'undefined' ? bonusData.is_first_deposit : '');
    data.append("deposit_times", bonusData.deposit_times && bonusData.deposit_times != 'undefined' ? bonusData.deposit_times : '');

    return instance.post("/api/bonus-code/edit", data, {
      headers: authHeader(),
    });
  }

  getBonusGroupLists(queryParams) {
    const pars = {
      name: queryParams.name,
      pageNumber: queryParams.page,
      status: queryParams.status,
    };
    return instance
      .get("/api/bonus-group/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          bonusGroups: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }

  addBonusGroup(bonusGroupData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("name", bonusGroupData.name);
    // data.append('content', bonusGroupData.content)
    // data.append('banner', bonusGroupData.banner)

    return instance.post("/api/bonus-group/create", data, {
      headers: authHeader(),
    });
  }

  editBonusGroup(bonusGroupData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("name", bonusGroupData.name);
    // data.append('content', bonusGroupData.content)
    data.append("bonus_group_id", bonusGroupData.bonus_group_id);
    data.append("status", bonusGroupData.status);
    // data.append('banner', bonusGroupData.banner)
    return instance.post("/api/bonus-group/edit", data, {
      headers: authHeader(),
    });
  }

  getMemberBonusCode(queryParams) {
    const pars = {
      username: queryParams.username,
      bonus_code: queryParams.bonus_code,
      pageNumber: queryParams.page,
      status: queryParams.status,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
    };
    return instance.get("/api/user-bonus-code/index", {
      headers: authHeader(),
      params: pars,
    });
  }

  updateStatusMemberBonusCode(memberBonusCodeData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("user_bonus_code_id", memberBonusCodeData.user_bonus_code_id);
    data.append("status", memberBonusCodeData.status);
    data.append("reject_reason", memberBonusCodeData.reject_reason);
    return instance.post("/api/user-bonus-code/update-status", data, {
      headers: authHeader(),
    });
  }

  getBonusTypes() {
    return instance.get("/api/bonus-type/index", { headers: authHeader() });
  }
  getBonusCodesManager(queryParams) {
    const pars = {
      user_id: queryParams.user_id,
      username: queryParams.username,
      phone: queryParams.phone,
      product_id: queryParams.product_id,
      bonus_code_id: queryParams.bonus_code_id,
      fromDate: queryParams.fromDate,

      ip: queryParams.ip,
      pageNumber: queryParams.page,
    };
    return instance.get("/api/user-bonus-code/index", {
      headers: authHeader(),
      params: pars,
    });
  }
  getGameTypeFilters() {
    return instance.get("/api/agent/index", { headers: authHeader() });
  }
  getBonusNewMember(queryParams) {
    const pars = {
      username: queryParams.username,
      // group_id: queryParams.group_id,
      // currency: queryParams.currency,
      // product_id: queryParams.product_id,
      // discount_type: queryParams.discount_type,
      pageNumber: queryParams.page,
      // status: queryParams.status,
      // start_effective: queryParams.start_effective,
      // end_effective: queryParams.end_effective,
    };
    return instance.get("/api/user-verify-daily/index", {
      headers: authHeader(),
      params: pars,
    });
  }
  getBonusCodesDetail(queryParams) {
    const pars = {
      user_bonus_code_id: queryParams.bonus_code_id,
    };
    return instance.get("/api/user-bonus-code/detail", {
      headers: authHeader(),
      params: pars,
    });
  }
  getUserBonusRequest(queryParams) {
    const pars = {
      bonus_id: queryParams.bonus_id,
      status: queryParams.status,
      username: queryParams.username,
      from_date: queryParams.fromDate,
      to_date: queryParams.toDate,
      page_number: queryParams.pageNumber
    };
    return instance.get("/api/user-bonus-request/index", {
      headers: authHeader(),
      params: pars,
    });
  }
  updateUserBonusRequestStatus(request) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("status", request.status);
    if (request.remark) {
      data.append("remark", request.remark);
    }
    data.append("userBonusRequestId", request.id);
    return instance.post("/api/user-bonus-request/update-status", data, {
      headers: authHeader(),
    });
  }
  editBonusNewMember(bonusGroupData) {
    const FormData = require("form-data");
    const data = new FormData();
    // data.append('name', bonusGroupData.name)
    // data.append('content', bonusGroupData.content)
    data.append("id", bonusGroupData.id);
    data.append("status", bonusGroupData.status);
    data.append("product_id", bonusGroupData.product_id);
    // data.append('banner', bonusGroupData.banner)
    return instance.post("/api/user-verify-daily/update-status", data, {
      headers: authHeader(),
    });
  }
  exportBonusNewMember(queryParams) {
    const pars = {
    };
    const data = {};
    return instance.post("/api/user-verify-daily/export", data, {
      headers: authHeader(),
      params: pars,
    });
  }
  exportBonusManager(queryParams) {
    const pars = {

    };
    const data = {};
    return instance.post("/api/user-bonus-code/export", data, {
      headers: authHeader(),
      params: pars,
    });
  }
  exportManagerCashback(queryParams) {
    const pars = {
      deposit_ref: queryParams.deposit_ref,
      ref: queryParams.ref,
      username: queryParams.username,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      pageNumber: queryParams.page,
      date: queryParams.date,
    };
    const data = {};
    return instance.post("/api/deposit-bonus-cashback/export", data, {
      headers: authHeader(),
      params: pars,
    });
  }

  updateExpireBonus(request) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", request.id);
    return instance.post("/api/user-bonus-code/update-expired", data, {
      headers: authHeader(),
    });
  }

  updateBonus(request) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", request.id);
    data.append("is_expired", request.is_expired);
    data.append("rollover", request.rollover);
    return instance.post("/api/user-bonus-code/update", data, {
      headers: authHeader(),
    });
  }

  getUserRollCall(queryParams) {
    const pars = {
      userId: queryParams.userId,
      username: queryParams.username,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      pageNumber: queryParams.pageNumber
    };
    return instance.get("/api/user-roll-call/index", {
      headers: authHeader(),
      params: pars,
    });
  }
}

export default new BonusService();
